<template>
  <div class="detail-prop">
    <div class="util">
      <div class="wrapper clearfix">
        <div class="crumb">
          <router-link to="/find-property">
            {{ $t("findProperty.DetailProperty.Title") }}
          </router-link>
          <i></i>
          <span>{{ $t("findProperty.DetailProperty.SubTitle") }}</span>
        </div>
        <!-- end of crumb -->
        <a href="#" class="report">Report this listing</a>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of util -->

    <div class="gallery">
      <div class="wrapper rowflex">
        <template v-if="property.mediaDescription">
          <div class="big">
            <div class="item">
              <a :href="property.mediaDescription[0]" data-fancybox="gallery">
                <template
                  v-if="checkType(property.mediaDescription[0]) == 'img'"
                >
                  <img :src="property.mediaDescription[0]" />
                </template>
                <template v-else>
                  <video style="width: 100%">
                    <source
                      :src="property.mediaDescription[0]"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </template>
              </a>
              <a
                href="#"
                class="type"
                v-if="property.propertyType"
                :style="{ background: property.propertyType.class }"
              ></a>
              <a class="type" v-if="property.propertyType">
                {{ propertyTypeTranslate(property.propertyType) }}
              </a>
              <a
                @click="bookmark(property.id)"
                class="cvr-bg pointer bookmark"
                :class="{ marked: property.isBookmarked }"
              ></a>
            </div>
            <!-- end of item -->
          </div>
          <!-- end of big -->
          <div class="small">
            <div class="item" v-if="property.mediaDescription.length >= 2">
              <a :href="property.mediaDescription[1]" data-fancybox="gallery">
                <template
                  v-if="checkType(property.mediaDescription[1]) == 'img'"
                >
                  <img :src="property.mediaDescription[1]" />
                </template>
                <template v-else>
                  <video style="width: 100%">
                    <source
                      :src="property.mediaDescription[1]"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </template>
              </a>
            </div>
            <!-- end of item -->
            <div class="item" v-if="property.mediaDescription.length >= 3">
              <a :href="property.mediaDescription[2]" data-fancybox="gallery">
                <template
                  v-if="checkType(property.mediaDescription[2]) == 'img'"
                >
                  <img :src="property.mediaDescription[2]" />
                </template>
                <template v-else>
                  <video style="width: 100%">
                    <source
                      :src="property.mediaDescription[2]"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </template>
              </a>
            </div>
            <!-- end of item -->
            <template v-for="(image, index) in property.mediaDescription">
              <template v-if="index > 2">
                <a
                  :href="image"
                  style="display: none"
                  data-fancybox="gallery"
                  :key="index"
                >
                  <img :src="image" />
                </a>
              </template>
            </template>
          </div>
          <!-- end of small -->
        </template>
        <template v-else>
          <div class="item">
            <a href="/img/dummy/home/11.jpg" data-fancybox="gallery">
              <img src="/img/dummy/home/11.jpg" />
            </a>
            <a
              href="#"
              class="type"
              v-if="property.propertyType"
              :style="{ background: property.propertyType.class }"
            ></a>
            <a class="type" v-if="property.propertyType">
              {{ propertyTypeTranslate(property.propertyType) }}
            </a>
          </div>
          <!-- end of item -->
        </template>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of gallery-->

    <div class="main">
      <div class="wrapper rowflex">
        <div class="content">
          <h1>{{ property.name }}</h1>
          <div class="location cvr-bg-bf">
            <span>{{ property.region }}, {{ property.city }}</span>
            <span v-if="property.furnishedType">
              {{ property.furnishedType.name }}
            </span>
          </div>
          <!-- end of location -->
          <div class="format-text" v-html="property.description"></div>
          <!-- end of format text -->

          <div class="info">
            <div class="fotem detail">
              <h3>
                {{ $t("findProperty.DetailProperty.PropertyDetails") }}
              </h3>
              <ul>
                <li>
                  <i><em class="cvr-bg ic1"></em></i>
                  <b>
                    {{ property.landSize | convertToRupiah }} m<sup>2</sup>
                  </b>
                  <span>
                    {{ $t("findProperty.DetailProperty.LandSize") }}
                  </span>
                </li>
                <li>
                  <i><em class="cvr-bg ic2"></em></i>
                  <b>{{ property.numBedroom }}</b>
                  <span>
                    {{ $t("findProperty.DetailProperty.Bedrooms") }}
                  </span>
                </li>
                <li>
                  <i><em class="cvr-bg ic3"></em></i>
                  <b>{{ property.numCarLot }}</b>
                  <span>
                    {{ $t("findProperty.DetailProperty.CarLots") }}
                  </span>
                </li>
                <li>
                  <i><em class="cvr-bg ic4"></em></i>
                  <b>
                    {{ property.buildingSize | convertToRupiah }} m<sup>2</sup>
                  </b>
                  <span>
                    {{ $t("findProperty.DetailProperty.BuildingSize") }}
                  </span>
                </li>
                <li>
                  <i><em class="cvr-bg ic5"></em></i>
                  <b>{{ property.numBathroom }}</b>
                  <span>
                    {{ $t("findProperty.DetailProperty.Bathrooms") }}
                  </span>
                </li>
                <li>
                  <i><em class="cvr-bg ic6"></em></i>
                  <b>{{ property.legalCertificate || "-" }}</b>
                  <span>
                    {{ $t("findProperty.DetailProperty.LegalCertificate") }}
                  </span>
                </li>
                <li>
                  <i><em class="cvr-bg ic7"></em></i>
                  <b>{{ property.wattage | convertToRupiah }}</b>
                  <span>
                    Watt
                  </span>
                </li>
                <li>
                  <i><em class="cvr-bg ic8"></em></i>
                  <b>{{ property.waterSource }}</b>
                  <span>
                    {{ $t("findProperty.DetailProperty.Water") }}
                  </span>
                </li>
              </ul>
            </div>
            <!-- end of item detail -->
            <div class="fotem near">
              <h3>
                {{ $t("findProperty.DetailProperty.NearestLocations") }}
              </h3>
              <ul>
                <li v-if="property.nearestElementarySchool !== undefined">
                  <span>
                    {{ $t("findProperty.DetailProperty.ElementarySchool") }}
                    <b>{{ property.nearestElementarySchool.distance }} km</b>
                  </span>
                  <ul v-if="property.nearestElementarySchool.places.length">
                    <li
                      v-for="(place, index) in property.nearestElementarySchool
                        .places"
                      :key="index"
                    >
                      {{ place }}
                    </li>
                  </ul>
                </li>
                <li v-if="property.nearestUniversity !== undefined">
                  <span>
                    {{ $t("findProperty.DetailProperty.University") }}
                    <b>{{ property.nearestUniversity.distance }} km</b>
                  </span>
                  <ul v-if="property.nearestUniversity.places.length">
                    <li
                      v-for="(place, index) in property.nearestUniversity
                        .places"
                      :key="index"
                    >
                      {{ place }}
                    </li>
                  </ul>
                </li>
                <li v-if="property.nearestShoppingCentre !== undefined">
                  <span>
                    {{ $t("findProperty.DetailProperty.ShoppingCentre") }}
                    <b>{{ property.nearestShoppingCentre.distance }} km</b>
                  </span>
                  <ul v-if="property.nearestShoppingCentre.places.length">
                    <li
                      v-for="(place, index) in property.nearestShoppingCentre
                        .places"
                      :key="index"
                    >
                      {{ place }}
                    </li>
                  </ul>
                </li>
                <li v-if="property.nearestMiddleSchool !== undefined">
                  <span>
                    {{ $t("findProperty.DetailProperty.MiddleSchool") }}
                    <b>{{ property.nearestMiddleSchool.distance }} km</b>
                  </span>
                  <ul v-if="property.nearestMiddleSchool.places.length">
                    <li
                      v-for="(place, index) in property.nearestMiddleSchool
                        .places"
                      :key="index"
                    >
                      {{ place }}
                    </li>
                  </ul>
                </li>
                <li v-if="property.nearestMinimarket !== undefined">
                  <span>
                    Minimarket
                    <b>{{ property.nearestMinimarket.distance }} km</b>
                  </span>
                  <ul v-if="property.nearestMinimarket.places.length">
                    <li
                      v-for="(place, index) in property.nearestMinimarket
                        .places"
                      :key="index"
                    >
                      {{ place }}
                    </li>
                  </ul>
                </li>
                <li v-if="property.nearestHospital !== undefined">
                  <span>
                    {{ $t("findProperty.DetailProperty.Hospital") }}
                    <b>{{ property.nearestHospital.distance }} km</b>
                  </span>
                  <ul v-if="property.nearestHospital.places.length">
                    <li
                      v-for="(place, index) in property.nearestHospital.places"
                      :key="index"
                    >
                      {{ place }}
                    </li>
                  </ul>
                </li>
                <li v-if="property.nearestHighSchool !== undefined">
                  <span>
                    {{ $t("findProperty.DetailProperty.HighSchool") }}
                    <b>{{ property.nearestHighSchool.distance }} km</b>
                  </span>
                  <ul v-if="property.nearestHighSchool.places.length">
                    <li
                      v-for="(place, index) in property.nearestHighSchool
                        .places"
                      :key="index"
                    >
                      {{ place }}
                    </li>
                  </ul>
                </li>
                <li v-if="property.nearestSupermarket !== undefined">
                  <span>
                    Supermarket
                    <b>{{ property.nearestSupermarket.distance }} km</b>
                  </span>
                  <ul v-if="property.nearestSupermarket.places.length">
                    <li
                      v-for="(place, index) in property.nearestSupermarket
                        .places"
                      :key="index"
                    >
                      {{ place }}
                    </li>
                  </ul>
                </li>
                <li v-if="property.nearestHighway !== undefined">
                  <span>
                    {{ $t("findProperty.DetailProperty.HighwayEnterance") }}
                    <b>{{ property.nearestHighway.distance }} km</b>
                  </span>
                  <ul v-if="property.nearestHighway.places.length">
                    <li
                      v-for="(place, index) in property.nearestHighway.places"
                      :key="index"
                    >
                      {{ place }}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- end of item detail -->
            <div
              class="fotem fees"
              v-if="
                (property.maintenanceFee !== '' &&
                  property.maintenanceFee !== undefined) ||
                  (property.neighbourhoodFee !== '' &&
                    property.neighbourhoodFee !== undefined) ||
                  (property.otherFee !== '' && property.otherFee !== undefined)
              "
            >
              <h3>{{ $t("findProperty.DetailProperty.Fee") }}</h3>
              <ul>
                <li
                  v-if="
                    property.maintenanceFee !== '' &&
                      property.maintenanceFee !== undefined &&
                      property.maintenanceFee !== null
                  "
                >
                  {{ $t("findProperty.DetailProperty.MaintenanceFee") }}
                  Rp. {{ property.maintenanceFee | convertToRupiah }}
                </li>
                <li
                  v-if="
                    property.neighbourhoodFee !== '' &&
                      property.neighbourhoodFee !== undefined &&
                      property.neighbourhoodFee !== null
                  "
                >
                  {{ $t("findProperty.DetailProperty.NeighborhoodFee") }}
                  Rp. {{ property.neighbourhoodFee | convertToRupiah }}
                </li>
                <li
                  v-if="
                    property.otherFee !== '' &&
                      property.otherFee !== undefined &&
                      property.otherFee !== null
                  "
                >
                  {{ $t("findProperty.DetailProperty.OtherFee") }}
                  Rp. {{ property.otherFee | convertToRupiah }}
                </li>
              </ul>
            </div>
            <!--end of item -->
            <div class="fotem map" v-if="property.mapUrl !== null">
              <h3>{{ $t("findProperty.DetailProperty.Map") }}</h3>
              <div class="iframe-holder" v-html="property.mapUrl"></div>
              <!-- end of iframe -->
            </div>
            <!-- end of item -->

            <div
              class="fotem event"
              v-if="property.event !== null && property.event !== undefined"
            >
              <h3>{{ $t("findProperty.DetailProperty.OpenHouseEvent") }}</h3>
              <div class="rowflex">
                <div class="item">
                  <a
                    href="javascript:"
                    :data-src="`#popup-event`"
                    data-fancybox
                  >
                    <figure>
                      <img
                        :src="property.event.banner"
                        @click="showModal = true"
                      />
                      <figcaption style="background: #219653">
                        {{ property.event.type }}
                      </figcaption>
                    </figure>
                    <div class="caption">
                      <h2>
                        {{ property.event.title }}
                      </h2>
                      <div class="meta">
                        <span class="ic-loc cvr-bg-bf">
                          {{ property.event.location }}
                        </span>
                        <span class="ic-date cvr-bg-bf">
                          {{ property.event.date | date_only }}
                        </span>
                        <span class="ic-clock cvr-bg-bf">
                          {{ property.event.start | date_only }}
                          -
                          {{ property.event.end | date_only }}
                        </span>
                      </div>
                      <!-- end of meta -->
                    </div>
                    <!-- end of caption -->
                  </a>
                  <div
                    :id="`popup-event`"
                    class="popup-event"
                    style="display: none;"
                  >
                    <div class="headback light">
                      <div class="wrapper">
                        <a href="#" class="cvr-bg-bf close-popup">
                          Detail Event
                        </a>
                      </div>
                      <!-- end of wrapper -->
                    </div>
                    <!-- end of headback -->
                    <div class="holder">
                      <h2>{{ property.event.title }}</h2>
                      <div class="content">
                        <figure>
                          <img :src="property.event.banner" />
                        </figure>
                        <div class="copy">
                          <div class="txt">
                            {{ property.event.description }}
                          </div>
                          <!-- end of txt -->
                          <div class="centered">
                            <template v-if="$store.state.userdata">
                              <a
                                href="#"
                                class="button medwide"
                                data-src="#popup-logout"
                                data-fancybox
                                @click="selectedEvent = event"
                              >
                                {{ $t("event.Book") }}
                              </a>
                            </template>
                            <template v-else>
                              <a
                                href="#"
                                class="button medwide"
                                data-src="#login-register"
                                data-fancybox
                              >
                                {{ $t("event.Book") }}
                              </a>
                            </template>
                          </div>
                          <!-- en dof centered -->
                        </div>
                        <!-- end of copy -->
                      </div>
                      <!-- end  of content -->
                    </div>
                    <!-- end of holder -->
                  </div>
                  <!-- end of popup -->
                </div>
              </div>
            </div>
          </div>
          <!-- end of info -->
        </div>
        <!-- end of content -->
        <FormProperty
          :property="property"
          @set-transactionType="setTransactionType"
        />
        <!-- end of action -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of main -->

    <div
      id="popup-logout"
      class="popup-logout form-basic"
      style="display: none;"
    >
      <h2>Choose Date</h2>
      <form>
        <div class="gap" style="margin-bottom: 20px">
          <fieldset>
            <input
              v-model="bookingDate"
              type="date"
              id="bookingDate"
              name="bookingDate"
            />
          </fieldset>
        </div>
      </form>
      <a href="#" ref="closePopup" class="button btn-cancel">Cancel</a>
      <a href="#" class="button btn-confirm" @click="bookEvent">Submit</a>
    </div>

    <!-- Similar Property -->
    <div
      class="property"
      v-if="
        property.transactionTypeId !== undefined && relatedProperties.length > 0
      "
    >
      <div class="list">
        <div class="wrapper">
          <h2 class="clearfix">
            {{
              $t(
                `findProperty.DetailProperty.SimilarProperty${transactionTypeInt}`
              )
            }}
          </h2>
          <div class="slider rto">
            <VueSlickCarousel
              ref="property1"
              v-if="relatedProperties.length"
              v-bind="{
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
                autoplay: false,
                speed: 500,
                autoplaySpeed: 5000,
                variableWidth: false,
                responsive: [
                  {
                    breakpoint: 1400,
                    settings: {
                      arrows: false,
                      slidesToShow: 1,
                      variableWidth: false
                    }
                  }
                ]
              }"
            >
              <template v-for="(property, index) in relatedProperties">
                <div class="item item-property" :key="index">
                  <figure>
                    <router-link
                      v-if="property.propertyType"
                      :to="`/property/${property.slug}#${transactionType}`"
                      class="type"
                      :style="
                        `background-color: ${property.propertyType.class}`
                      "
                    >
                      {{ property.propertyType.name }}
                    </router-link>
                    <a
                      @click="bookmark(property.id)"
                      class="cvr-bg pointer bookmark"
                      :class="{ marked: property.isBookmarked }"
                    ></a>
                    <router-link
                      :to="`/property/${property.slug}#${transactionType}`"
                    >
                      <template
                        v-if="
                          property.mediaDescription === null ||
                            property.mediaDescription.length === 0
                        "
                      >
                        <img src="/img/dummy/home/1.jpg" />
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            checkType(property.mediaDescription[0]) == 'video'
                          "
                        >
                          <video style="width: 100%" controls autoplay>
                            <source
                              :src="property.mediaDescription[0]"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </template>
                        <template v-else>
                          <img :src="property.mediaDescription[0]" />
                        </template>
                      </template>
                    </router-link>
                  </figure>
                  <div class="caption">
                    <h3>
                      <router-link
                        :to="`/property/${property.slug}#${transactionType}`"
                      >
                        {{ property.name }}
                      </router-link>
                    </h3>
                    <div class="location cvr-bg-bf">
                      <span>{{ property.region }}, {{ property.city }}</span>
                      <span v-if="property.furnishedType">
                        {{ property.furnishedType.name }}
                      </span>
                    </div>
                    <!-- end of location -->
                    <strong class="price">
                      <b>Rp. {{ property.ratesMonthly | convertToRupiah }}</b>
                      <small> / {{ $t("findProperty.Month") }}</small>
                      <em>Rp. {{ property.rates | convertToRupiah }}</em>
                    </strong>
                    <div class="amen">
                      <span
                        class="cvr-bg-bf ic-bed"
                        v-if="showBedAndBath(property.propertyTypeId)"
                      >
                        {{ property.numBedroom }}
                      </span>
                      <span
                        class="cvr-bg-bf ic-bath"
                        v-if="showBedAndBath(property.propertyTypeId)"
                      >
                        {{ property.numBathroom }}
                      </span>
                      <span class="cvr-bg-bf ic-land">
                        {{ property.landSize | convertToRupiah }} m
                        <sup>2</sup>
                      </span>
                      <span class="cvr-bg-bf ic-build">
                        {{ property.buildingSize | convertToRupiah }} m
                        <sup>2</sup>
                      </span>
                    </div>
                    <!-- end of amen -->
                  </div>
                </div>
                <!-- end of item -->
              </template>
            </VueSlickCarousel>
            <!--end of slick -->
            <div class="slidenav">
              <a
                class="prev pointer cvr-bg"
                @click="$refs.property1.prev()"
              ></a>
              <a
                class="next pointer cvr-bg"
                @click="$refs.property1.next()"
              ></a>
            </div>
          </div>
          <!-- end of slider -->
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of list -->
    </div>
    <!-- end of property -->
    <button
      data-src="#login-register"
      type="button"
      class="vhidden"
      data-fancybox
      ref="logreg"
    ></button>
  </div>
  <!-- end of detail prop-->
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import FormProperty from "@/components/Transaction/FormProperty.vue";
import $ from "jquery";

export default {
  name: "DetailProperty",
  metaInfo() {
    return {
      title: this.property.title
    };
  },
  components: {
    VueSlickCarousel,
    FormProperty
  },
  data() {
    return {
      property: {},
      transactionType: null,
      transactionTypeInt: null,
      relatedProperties: [],
      bookingDate: null
    };
  },
  mounted() {
    this.getDetailProperty();

    $(".popup-logout .button.btn-cancel").on("click", function(e) {
      e.preventDefault();
      $(this)
        .closest(".popup-logout")
        .find(".fancybox-close-small")
        .trigger("click");
    });
  },
  methods: {
    getDetailProperty() {
      this.$axios
        .get(`/properties/${this.$route.params.id}`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          console.log(response.data);
          this.property = response.data.data;
        });
    },
    getSimilarProperties(type) {
      this.$axios
        .get("/properties/similar-with", {
          params: {
            transactionType: type,
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.relatedProperties = response.data.data;
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    setTransactionType(val) {
      let type;
      if (val === "rent") {
        type = 1;
      } else if (val === "rent-to-own") {
        type = 2;
      } else {
        type = 3;
      }
      this.transactionType = val;
      this.transactionTypeInt = type;
      this.getSimilarProperties(type);
    },
    bookmark(propertyId) {
      if (this.$store.state.userdata) {
        this.$axios
          .post("/properties/bookmarks", {
            id: propertyId
          })
          .then(response => {
            this.$swal({
              icon: "success",
              text: response.data.message
            });
          });
      } else {
        this.$refs.logreg.click();
      }
    },
    bookEvent() {
      this.$axios
        .post(`/events/${this.property.event.id}/booking`, {
          date: this.bookingDate
        })
        .then(response => {
          this.$swal({
            icon: "success",
            text: response.data.message
          });
          this.$refs.closePopup.click();
        })
        .catch(error => {
          this.$swal({
            icon: "error",
            text: error.response.data.message
          });
        });
    }
  }
};
</script>

<style scoped>
.item {
  margin-left: 0px !important;
}
</style>
