<template>
  <div class="submis">
    <div class="layer">
      <div class="htype">
        <h3>
          {{ $t("findProperty.DetailProperty.TransactionType") }}
        </h3>
        <div class="radio-inline type-switch">
          <label
            v-if="
              transactionTypeOption.includes('Rent') ||
                transactionTypeOption.includes('Sewa')
            "
          >
            <input
              type="radio"
              name="type-item"
              value="rent"
              v-model="transactionType"
            />
            <span v-if="$store.state.locale.lang == 'en'">Rent</span>
            <span v-if="$store.state.locale.lang == 'id'">Sewa</span>
          </label>
          <label
            v-if="
              transactionTypeOption.includes('Rent-to-Own') ||
                transactionTypeOption.includes('Sewa-untuk-Beli')
            "
          >
            <input
              type="radio"
              name="type-item"
              value="rent-to-own"
              v-model="transactionType"
            />
            <span v-if="$store.state.locale.lang == 'en'">
              Rent-to-Own
            </span>
            <span v-if="$store.state.locale.lang == 'id'">
              Rental-untuk-Beli
            </span>
          </label>
          <label
            v-if="
              transactionTypeOption.includes('Buy') ||
                transactionTypeOption.includes('Beli')
            "
          >
            <input
              type="radio"
              name="type-item"
              value="buy"
              v-model="transactionType"
            />
            <span v-if="$store.state.locale.lang == 'en'">Buy</span>
            <span v-if="$store.state.locale.lang == 'id'">Beli</span>
          </label>
        </div>
        <!-- end of radio inline -->
      </div>
      <!-- end of htype -->

      <!-- SECTION TRANSACTION RENT -->
      <Rent :property="property" :show="transactionType == 'rent'" />
      <!-- END SECTION TRANSACTION RENT -->

      <!-- SECTION TRANSACTION RENT TOT OWN -->
      <RentToOwn
        :property="property"
        :show="transactionType == 'rent-to-own'"
      />
      <!-- END SECTION TRANSACTION RENT T0 TOWN -->

      <!-- SECTION TRANSACTION BUY -->
      <Buy :property="property" :show="transactionType == 'buy'" />
      <!-- END SECTION TRANSACTION BUY -->
    </div>
    <!-- end of layer -->

    <div class="tour" v-if="property.videoCallTour || property.inPersonTour">
      <h3>
        {{ $t("findProperty.DetailProperty.Availability") }}
      </h3>
      <ul>
        <li class="cvr-bg-bf" v-if="property.inPersonTour">
          {{ $t("findProperty.DetailProperty.PersonTour") }}
        </li>
        <li class="cvr-bg-bf" v-if="property.videoCallTour">
          {{ $t("findProperty.DetailProperty.VideoTour") }}
        </li>
      </ul>
    </div>
    <!-- end of tour -->
  </div>
</template>

<script>
import Rent from "./Rent.vue";
import RentToOwn from "./RentToOwn.vue";
import Buy from "./Buy.vue";

export default {
  name: "FormTransaction",
  props: {
    property: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Rent,
    RentToOwn,
    Buy
  },
  data() {
    return {
      transactionType: null,
      transactionTypeOption: []
    };
  },
  watch: {
    property(val) {
      this.transactionTypeOption = val.transactionType;
      if (this.$route.hash !== "") {
        this.transactionType = this.$route.hash.replace("#", "");
      } else {
        // jika tidak ada hash pada url, maka option pertama auto selected
        if (
          val.transactionType[0] == "Sewa" ||
          val.transactionType[0] == "Rent"
        ) {
          this.transactionType = "rent";
        } else if (
          val.transactionType[0] == "Sewa-untuk-Beli" ||
          val.transactionType[0] == "Rent-to-Own"
        ) {
          this.transactionType = "rent-to-own";
        } else if (
          val.transactionType[0] == "Beli" ||
          val.transactionType[0] == "Buy"
        ) {
          this.transactionType = "buy";
        }
      }
    },
    transactionType(val) {
      this.$emit("set-transactionType", val);
    }
  }
};
</script>
