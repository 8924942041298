var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"submis"},[_c('div',{staticClass:"layer"},[_c('div',{staticClass:"htype"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("findProperty.DetailProperty.TransactionType"))+" ")]),_c('div',{staticClass:"radio-inline type-switch"},[(
            _vm.transactionTypeOption.includes('Rent') ||
              _vm.transactionTypeOption.includes('Sewa')
          )?_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.transactionType),expression:"transactionType"}],attrs:{"type":"radio","name":"type-item","value":"rent"},domProps:{"checked":_vm._q(_vm.transactionType,"rent")},on:{"change":function($event){_vm.transactionType="rent"}}}),(_vm.$store.state.locale.lang == 'en')?_c('span',[_vm._v("Rent")]):_vm._e(),(_vm.$store.state.locale.lang == 'id')?_c('span',[_vm._v("Sewa")]):_vm._e()]):_vm._e(),(
            _vm.transactionTypeOption.includes('Rent-to-Own') ||
              _vm.transactionTypeOption.includes('Sewa-untuk-Beli')
          )?_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.transactionType),expression:"transactionType"}],attrs:{"type":"radio","name":"type-item","value":"rent-to-own"},domProps:{"checked":_vm._q(_vm.transactionType,"rent-to-own")},on:{"change":function($event){_vm.transactionType="rent-to-own"}}}),(_vm.$store.state.locale.lang == 'en')?_c('span',[_vm._v(" Rent-to-Own ")]):_vm._e(),(_vm.$store.state.locale.lang == 'id')?_c('span',[_vm._v(" Rental-untuk-Beli ")]):_vm._e()]):_vm._e(),(
            _vm.transactionTypeOption.includes('Buy') ||
              _vm.transactionTypeOption.includes('Beli')
          )?_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.transactionType),expression:"transactionType"}],attrs:{"type":"radio","name":"type-item","value":"buy"},domProps:{"checked":_vm._q(_vm.transactionType,"buy")},on:{"change":function($event){_vm.transactionType="buy"}}}),(_vm.$store.state.locale.lang == 'en')?_c('span',[_vm._v("Buy")]):_vm._e(),(_vm.$store.state.locale.lang == 'id')?_c('span',[_vm._v("Beli")]):_vm._e()]):_vm._e()])]),_c('Rent',{attrs:{"property":_vm.property,"show":_vm.transactionType == 'rent'}}),_c('RentToOwn',{attrs:{"property":_vm.property,"show":_vm.transactionType == 'rent-to-own'}}),_c('Buy',{attrs:{"property":_vm.property,"show":_vm.transactionType == 'buy'}})],1),(_vm.property.videoCallTour || _vm.property.inPersonTour)?_c('div',{staticClass:"tour"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("findProperty.DetailProperty.Availability"))+" ")]),_c('ul',[(_vm.property.inPersonTour)?_c('li',{staticClass:"cvr-bg-bf"},[_vm._v(" "+_vm._s(_vm.$t("findProperty.DetailProperty.PersonTour"))+" ")]):_vm._e(),(_vm.property.videoCallTour)?_c('li',{staticClass:"cvr-bg-bf"},[_vm._v(" "+_vm._s(_vm.$t("findProperty.DetailProperty.VideoTour"))+" ")]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }