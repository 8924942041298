<template>
  <div id="type-rentown" class="item" :class="{ current: show }">
    <div class="fgroup">
      <h3>{{ $t("findProperty.DetailProperty.RentPeriod") }}</h3>
      <div class="radio-inline">
        <template v-for="(period, index) in rentPeriods">
          <label :key="index">
            <input
              type="radio"
              name="rentPeriod"
              :id="`rentPeriod-${period.id}`"
              :value="period.id"
              v-model="rentPeriod"
            />
            <span>{{ period.name }}</span>
          </label>
        </template>
      </div>
      <!-- end of radio inline -->
    </div>
    <!-- end of fgroup -->

    <div class="fgroup">
      <h3>{{ $t("findProperty.DetailProperty.PaymentOption") }}</h3>
      <div class="radio-inline">
        <template v-for="(payment, index) in paymentOptions">
          <label :key="index">
            <input
              type="radio"
              name="paymentOption"
              :id="`paymentOption-${payment.slug}`"
              :value="payment.slug"
              v-model="paymentOption"
            />
            <span>{{ payment.name }}</span>
          </label>
        </template>
      </div>
      <!-- end of radio inline -->
    </div>
    <!-- end of fgroup -->

    <div class="fgroup txt">
      <p>
        {{ $t("findProperty.DetailProperty.WhyDifferentPrice") }}
        <a
          href="javascript:;"
          data-src="#popup-price-txt-rentown"
          data-fancybox
          >{{ $t("findProperty.DetailProperty.ShowDetail") }}</a
        >
      </p>
      <div id="popup-price-txt-rentown" class="popup-txt">
        <h2>{{ $t("findProperty.DetailProperty.WhyDifferentPrice") }}</h2>
        <div class="format-text">
          <p>
            {{ $t("findProperty.DetailProperty.DifferentPriceDesc") }}
          </p>
        </div>
      </div>
    </div>
    <!-- end of fgroup -->

    <div class="fgroup pricing">
      <strong class="price" v-if="formulatedPriceRent === null">
        <b>
          Rp.
          {{ property.ratesAfterCalulate.ratePerMonth | convertToRupiah }}
        </b>
        <small>/ {{ $t("findProperty.Month") }}</small>
        <em>
          Rp.
          {{
            property.ratesAfterCalulate.propertyPriceEndOfRentalPeriod
              | convertToRupiah
          }}
        </em>
      </strong>
      <strong class="price" v-if="formulatedPriceRent !== null">
        <b>Rp {{ formulatedPriceRent | convertToRupiah }}</b>
        <small>/ {{ $t("findProperty.Month") }}</small>
        <em>Rp. {{ formulatedPriceBuy | convertToRupiah }}</em>
      </strong>
    </div>
    <!-- end of fgroup -->

    <div class="fgroup action">
      <a
        href="https://wa.me/+6287708779768"
        target="_blank"
        class="cvr-bg-bf msg pointer"
      ></a>
      <a
        class="button btn-hollow"
        href="https://wa.me/+6287708779768"
        target="_blank"
      >
        {{ $t("findProperty.DetailProperty.MakeAnOffer") }}
      </a>
      <a class="button" href="https://wa.me/+6287708779768" target="_blank">
        {{ $t("findProperty.DetailProperty.BookNow") }}
      </a>
      <!-- <button
        type="button"
        @click="offerProperty"
        :disabled="disabledAction"
        class="button btn-hollow"
      >
        {{ $t("findProperty.DetailProperty.MakeAnOffer") }}
      </button>
      -->
      <!-- <button
        type="button"
        @click="bookProperty"
        :disabled="disabledAction"
        class="button"
      >
        {{ $t("findProperty.DetailProperty.BookNow") }}
      </button> -->
      <a
        ref="ShowBook"
        class="vhidden"
        data-src="#popup-book-rentown"
        data-fancybox
      >
        PopUp Book
      </a>
      <a
        ref="ShowOffer"
        class="vhidden"
        data-src="#popup-offer-rentown"
        data-fancybox
      >
        PopUp Offer
      </a>
      <div id="popup-book-rentown" class="popup-form">
        <h2>Booking</h2>
        <form @submit.prevent="submitBook">
          <div class="gap">
            <div class="fgroup">
              <label>Planned Move In Date</label>
              <fieldset class="cvr-bg-af">
                <input
                  type="date"
                  required
                  v-model="moveDateBook"
                  class="datepicker-here"
                  :min="minDate"
                />
              </fieldset>
            </div>
            <div class="fgroup">
              &nbsp;
            </div>
          </div>
          <div class="fgroup">
            <label>End of Rental Period & Purchase Date</label>
            <fieldset class="cvr-bg-af">
              <input
                type="date"
                required
                v-model="endDateRental"
                class="datepicker-here"
                :min="minDate"
              />
            </fieldset>
          </div>
          <div class="fgroup">
            <button type="submit" class="button">Next</button>
          </div>
        </form>
      </div>
      <!-- end of popup form -->
      <div id="popup-offer-rentown" class="popup-form">
        <h2>{{ $t("findProperty.DetailProperty.MakeAnOffer") }}</h2>
        <form @submit.prevent="submitOffer">
          <div class="gap">
            <div class="fgroup">
              <label>Planned Move In Date</label>
              <fieldset class="cvr-bg-af">
                <input
                  type="date"
                  required
                  v-model="moveDate"
                  class="datepicker-here"
                  :min="minDate"
                />
              </fieldset>
            </div>
            <div class="fgroup">&nbsp;</div>
          </div>
          <div class="fgroup">
            <p class="amount clearfix">
              Effective Rent Per Month
              <strong>{{ rateEffectivePrice | convertToRupiah }}</strong>
            </p>
          </div>
          <div class="fgroup">
            <label>{{ $t("findProperty.DetailProperty.MakeAnOffer") }}</label>
            <input
              type="text"
              required
              placeholder="Rp 0"
              v-model="offer.rent"
              @keyup="formatRupiah('rent')"
            />
          </div>
          <div class="fgroup">
            <p class="amount clearfix">
              Purchase Price at End of <br />Rental Period
              <strong style="top: -25px;">
                {{ endOfRentalPeriodPrice | convertToRupiah }}
              </strong>
            </p>
          </div>
          <div class="fgroup">
            <label>{{ $t("findProperty.DetailProperty.MakeAnOffer") }}</label>
            <input
              type="text"
              required
              placeholder="Rp 0"
              v-model="offer.buy"
              @keyup="formatRupiah('buy')"
            />
          </div>
          <div class="fgroup">
            <button type="submit" class="button">Send</button>
          </div>
          <div class="fgroup">
            <small
              >*Note that this is a binding offer. If offer is accepted, the
              transaction must be made.</small
            >
          </div>
        </form>
      </div>
      <!-- end of popup form -->
    </div>
    <!-- end of fgroup -->
    <div class="fgroup txt">
      <p>
        {{ $t("findProperty.DetailProperty.NotInclude") }}
        <a
          href="javascript:;"
          data-src="#popup-incl-txt-rentown"
          data-fancybox
          >{{ $t("findProperty.DetailProperty.ShowDetail") }}</a
        >
      </p>
      <div
        id="popup-incl-txt-rentown"
        class="popup-txt"
        v-if="content !== null"
      >
        <h2>{{ content.title }}</h2>
        <div class="format-text">
          <h4 v-if="content.rent !== undefined">{{ content.rent.title }}</h4>
          <ul v-if="content.rent !== undefined">
            <li v-for="(content, index) in content.rent.contents" :key="index">
              - {{ content }}
            </li>
          </ul>
          <h4 v-if="content.transaction !== undefined">
            {{ content.transaction.title }}
          </h4>
          <ul v-if="content.transaction !== undefined">
            <li
              v-for="(content, index) in content.transaction.contents"
              :key="index"
            >
              - {{ content }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end of fgroup -->
    <button
      ref="logreg"
      style="visibility: hidden"
      data-src="#login-register"
      data-fancybox
    ></button>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TransactionRentToOwn",
  props: {
    property: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formulatedPriceRent: null,
      formulatedPriceBuy: null,
      rateEffectivePrice: null,
      endOfRentalPeriodPrice: null,
      content: null,
      rentPeriod: null,
      rentPeriods: [],
      paymentOption: null,
      paymentOptions: [],
      moveDate: null,
      moveDateBook: null,
      endDateRental: null,
      offer: {
        rent: null,
        buy: null
      },
      priceDetail: null
    };
  },
  mounted() {
    this.getContent("rent-to-own");
    this.getPaymentOptions();
    this.getRentPeriod();
  },
  watch: {
    rentPeriod() {
      this.getPaymentOptions();
      this.calculatePrice();
    },
    paymentOption() {
      this.calculatePrice();
    }
  },
  computed: {
    disabledAction() {
      if (this.rentPeriod !== null && this.paymentOption !== null) {
        return false;
      } else {
        return true;
      }
    },
    minDate() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    getContent(slug) {
      this.$axios
        .get(`/contents/${slug}`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.content = response.data.data.description;
        });
    },
    getRentPeriod() {
      this.$axios
        .get("/misc/rent-periods", {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.rentPeriods = response.data.data;
        });
    },
    getPaymentOptions() {
      this.$axios
        .get("/misc/payment-options", {
          params: {
            lang: this.$store.state.locale.lang,
            rentPeriod: this.rentPeriod
          }
        })
        .then(response => {
          this.paymentOptions = response.data.data;
        });
    },
    calculatePrice() {
      this.$axios
        .post("/properties/payment-option", {
          transactionType: 2,
          slug: this.paymentOption,
          ratesMonthly: this.property.ratesMonthly,
          rates: this.property.rates,
          rentPeriod: this.rentPeriod
        })
        .then(response => {
          this.formulatedPriceRent = response.data.data.ratePerMonth;
          this.formulatedPriceBuy =
            response.data.data.propertyPriceEndOfRentalPeriod;
          this.rateEffectivePrice = response.data.data.rateEffectivePerMonth;
          this.endOfRentalPeriodPrice =
            response.data.data.propertyPriceEndOfRentalPeriod;
          this.$store.commit("SET_PRICE_DETAIL", {
            ...response.data.data,
            endDateRental: this.endDateRental,
            rentPeriod: this.rentPeriods.find(
              item => item.id === this.rentPeriod
            ).name,
            paymentOption: this.paymentOptions.find(
              item => item.slug === this.paymentOption
            ).name
          });
        });
    },
    chatCSA() {
      if (this.$store.state.userdata) {
        this.$axios
          .post(`/conversations/init-csa`, {
            ref: `${this.base_url}/property/${this.property.slug}`,
            baseCity: this.property.city
          })
          .then(response => {
            this.$router.push(
              `/account/message?conversation=${response.data.data.id}`
            );
          })
          .catch(error => {
            console.log(error);
            this.$router.push("/account/message");
          });
      } else {
        this.$refs.logreg.click();
      }
    },
    offerProperty() {
      if (this.$store.state.userdata) {
        this.$refs.ShowOffer.click();
      } else {
        this.$refs.logreg.click();
      }
    },
    bookProperty() {
      if (this.$store.state.userdata) {
        this.$refs.ShowBook.click();
      } else {
        this.$refs.logreg.click();
      }
    },
    formatRupiah(model) {
      const angka = this.convertToAngka(this.offer[model]);
      const rupiah = this.convertToRupiah(angka, false);
      this.offer[model] = rupiah;
    },
    submitOffer() {
      this.$axios
        .post("/offers", {
          transactionType: 1,
          propertyId: this.property.id,
          moveDate: this.moveDate,
          rates: this.convertToAngka(this.offer.buy),
          ratesMonthly: this.convertToAngka(this.offer.rent)
        })
        .then(response => {
          const close = document.getElementsByClassName("fancybox-close-small");
          close[0].click();
          this.$swal({
            icon: "success",
            text: response.data.message
          });
          this.$router.push("/account/transaction");
        });
    },
    submitBook() {
      this.$axios
        .post("/booking/rent", {
          transactionTypeId: 2,
          rentPeriodId: this.rentPeriod,
          paymentOption: this.paymentOption,
          propertyId: this.property.id,
          moveDate: this.moveDateBook
        })
        .then(response => {
          const close = document.getElementsByClassName("fancybox-close-small");
          close[0].click();
          this.$swal({
            icon: "success",
            text: response.data.message
          });
          this.$store.commit("SET_BOOKING", response.data.data);
          // update move out
          const priceDetail = this.$store.state.priceDetail;
          this.$store.commit("SET_PRICE_DETAIL", {
            ...priceDetail,
            endDateRental: this.endDateRental
          });
          this.$router.push(
            `/property/${this.$route.params.id}/booking/rent-to-own`
          );
        });
    }
  }
};
</script>
