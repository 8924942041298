<template>
  <div id="type-buy" class="item" :class="{ current: show }">
    <div class="fgroup pricing">
      <strong class="price price-buy">
        <em>Rp. {{ property.rates | convertToRupiah }}</em>
      </strong>
    </div>
    <!-- end of fgroup -->

    <div class="fgroup action">
      <a
        href="https://wa.me/+6287708779768"
        target="_blank"
        class="cvr-bg-bf msg pointer"
      ></a>
      <a
        class="button btn-hollow"
        href="https://wa.me/+6287708779768"
        target="_blank"
      >
        {{ $t("findProperty.DetailProperty.MakeAnOffer") }}
      </a>
      <a class="button" href="https://wa.me/+6287708779768" target="_blank">
        {{ $t("findProperty.DetailProperty.BookNow") }}
      </a>
      <!-- <button type="button" @click="offerProperty" class="button btn-hollow">
        {{ $t("findProperty.DetailProperty.MakeAnOffer") }}
      </button>
      -->
      <!-- <button type="button" @click="bookProperty" class="button">
        {{ $t("findProperty.DetailProperty.BookNow") }}
      </button> -->
      <a
        ref="ShowBook"
        class="vhidden"
        data-src="#popup-book-buy"
        data-fancybox
      >
        PopUp Book
      </a>
      <a
        ref="ShowOffer"
        class="vhidden"
        data-src="#popup-offer-buy"
        data-fancybox
      >
        PopUp Offer
      </a>
      <div id="popup-book-buy" class="popup-form">
        <h2>Booking</h2>
        <form @submit.prevent="submitBook">
          <div class="fgroup">
            <label>Planned Purchase & Settlement Date</label>
            <fieldset class="cvr-bg-af">
              <input
                type="date"
                required
                v-model="moveDateBook"
                class="datepicker-here"
                :min="minDate"
              />
            </fieldset>
          </div>
          <div class="fgroup">
            <button type="submit" class="button">Next</button>
          </div>
        </form>
      </div>
      <!-- end of popup form -->
      <div id="popup-offer-buy" class="popup-form">
        <h2>{{ $t("findProperty.DetailProperty.MakeAnOffer") }}</h2>
        <form @submit.prevent="submitOffer">
          <div class="fgroup">
            <label>Planned Purchase & Settlement Date</label>
            <fieldset class="cvr-bg-af">
              <input
                type="date"
                required
                v-model="moveDate"
                class="datepicker-here"
                :min="minDate"
              />
            </fieldset>
          </div>
          <div class="fgroup">
            <p class="amount clearfix">
              Purchase Price
              <strong>{{ property.rates | convertToRupiah }}</strong>
            </p>
          </div>
          <div class="fgroup">
            <label>{{ $t("findProperty.DetailProperty.MakeAnOffer") }}</label>
            <input
              type="text"
              required
              placeholder="Rp 0"
              v-model="offer"
              @keyup="formatRupiah()"
            />
          </div>
          <div class="fgroup">
            <button type="submit" class="button">Send</button>
          </div>
          <div class="fgroup">
            <small
              >*Note that this is a binding offer. If offer is accepted, the
              transaction must be made.</small
            >
          </div>
        </form>
      </div>
      <!-- end of popup form -->
    </div>
    <!-- end of fgroup -->
    <div class="fgroup txt">
      <p>
        {{ $t("findProperty.DetailProperty.NotInclude") }}
        <a href="javascript:;" data-src="#popup-incl-txt-buy" data-fancybox>{{
          $t("findProperty.DetailProperty.ShowDetail")
        }}</a>
      </p>
      <div id="popup-incl-txt-buy" class="popup-txt">
        <h2>{{ content.title }}</h2>
        <div class="format-text">
          <ul>
            <li v-for="(content, index) in content.contents" :key="index">
              - {{ content }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end of fgroup -->
    <button
      ref="logreg"
      style="visibility: hidden"
      data-src="#login-register"
      data-fancybox
    ></button>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TransactionBuy",
  props: {
    property: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: {},
      moveDateBook: null,
      moveDate: null,
      offer: null
    };
  },
  mounted() {
    this.getContent("buy");
  },
  computed: {
    minDate() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    getContent(slug) {
      this.$axios
        .get(`/contents/${slug}`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.content = response.data.data.description;
        });
    },
    chatCSA() {
      if (this.$store.state.userdata) {
        this.$axios
          .post(`/conversations/init-csa`, {
            ref: `${this.base_url}/property/${this.property.slug}`,
            baseCity: this.property.city
          })
          .then(response => {
            this.$router.push(
              `/account/message?conversation=${response.data.data.id}`
            );
          })
          .catch(error => {
            console.log(error);
            this.$router.push("/account/message");
          });
      } else {
        this.$refs.logreg.click();
      }
    },
    offerProperty() {
      if (this.$store.state.userdata) {
        this.$refs.ShowOffer.click();
      } else {
        this.$refs.logreg.click();
      }
    },
    bookProperty() {
      if (this.$store.state.userdata) {
        this.$refs.ShowBook.click();
      } else {
        this.$refs.logreg.click();
      }
    },
    formatRupiah() {
      const angka = this.convertToAngka(this.offer);
      const rupiah = this.convertToRupiah(angka, false);
      this.offer = rupiah;
    },
    submitOffer() {
      this.$axios
        .post("/offers", {
          transactionType: 3,
          propertyId: this.property.id,
          moveDate: this.moveDate,
          rates: this.convertToAngka(this.offer)
        })
        .then(response => {
          const close = document.getElementsByClassName("fancybox-close-small");
          close[0].click();
          this.$swal({
            icon: "success",
            text: response.data.message
          });
          this.$router.push("/account/transaction");
        });
    },
    submitBook() {
      this.$axios
        .post("/booking/buy", {
          propertyId: this.property.id,
          moveDate: this.moveDateBook
        })
        .then(response => {
          const close = document.getElementsByClassName("fancybox-close-small");
          close[0].click();
          this.$store.commit("SET_BOOKING", response.data.data);
          this.$router.push(`/property/${this.$route.params.id}/booking/buy`);
        });
    }
  }
};
</script>
