<template>
  <div id="type-rent" class="item" :class="{ current: show }">
    <div class="fgroup">
      <h3>
        {{ $t("findProperty.DetailProperty.RentPeriod") }}
      </h3>
      <div class="radio-inline">
        <template v-for="(period, index) in rentPeriods">
          <label :key="index">
            <input
              type="radio"
              name="rentPeriod"
              :id="`rentPeriod-${period.id}`"
              :value="period.id"
              v-model="rentPeriod"
            />
            <span>{{ period.name }}</span>
          </label>
        </template>
      </div>
      <!-- end of radio inline -->
    </div>
    <!-- end of fgroup -->

    <div class="fgroup">
      <h3>
        {{ $t("findProperty.DetailProperty.PaymentOption") }}
      </h3>
      <div class="radio-inline">
        <template v-for="(payment, index) in paymentOptions">
          <label :key="index">
            <input
              type="radio"
              name="paymentOption"
              :id="`paymentOption-${payment.slug}`"
              :value="payment.slug"
              v-model="paymentOption"
            />
            <span>{{ payment.name }}</span>
          </label>
        </template>
      </div>
      <!-- end of radio inline -->
    </div>
    <!-- end of fgroup -->

    <div class="fgroup txt">
      <p>
        {{ $t("findProperty.DetailProperty.WhyDifferentPrice") }}
        <a href="javascript:;" data-src="#popup-price-txt-rent" data-fancybox>{{
          $t("findProperty.DetailProperty.ShowDetail")
        }}</a>
      </p>
      <div id="popup-price-txt-rent" class="popup-txt">
        <h2>{{ $t("findProperty.DetailProperty.WhyDifferentPrice") }}</h2>
        <div class="format-text">
          <p>
            {{ $t("findProperty.DetailProperty.DifferentPriceDesc") }}
          </p>
        </div>
      </div>
    </div>
    <!-- end of fgroup -->

    <div class="fgroup pricing">
      <strong class="price" v-if="formulatedPrice === null">
        <b>Rp. {{ property.ratesMonthly | convertToRupiah }}</b>
        <small>/ {{ $t("findProperty.Month") }}</small>
      </strong>
      <strong class="price" v-if="formulatedPrice !== null">
        <b>Rp. {{ formulatedPrice | convertToRupiah }}</b>
        <small>/ {{ $t("findProperty.Month") }}</small>
      </strong>
    </div>
    <!-- end of fgroup -->

    <div class="fgroup action">
      <a
        href="https://wa.me/+6287708779768"
        target="_blank"
        class="cvr-bg-bf msg pointer"
      ></a>
      <a
        class="button btn-hollow"
        href="https://wa.me/+6287708779768"
        target="_blank"
      >
        {{ $t("findProperty.DetailProperty.MakeAnOffer") }}
      </a>
      <a class="button" href="https://wa.me/+6287708779768" target="_blank">
        {{ $t("findProperty.DetailProperty.BookNow") }}
      </a>
      <!-- <button
        type="button"
        @click="offerProperty"
        :disabled="disabledAction"
        class="button btn-hollow"
      >
        {{ $t("findProperty.DetailProperty.MakeAnOffer") }}
      </button>
      -->
      <!-- <button
        type="button"
        :disabled="disabledAction"
        @click="bookProperty"
        class="button"
      >
        {{ $t("findProperty.DetailProperty.BookNow") }}
      </button> -->
      <a
        ref="ShowBook"
        class="vhidden"
        data-src="#popup-book-rent"
        data-fancybox
      >
        PopUp Book
      </a>
      <a
        ref="ShowOffer"
        class="vhidden"
        data-src="#popup-offer-rent"
        data-fancybox
      >
        PopUp Offer
      </a>
      <div id="popup-book-rent" class="popup-form">
        <h2>Booking</h2>
        <form @submit.prevent="submitBook">
          <div class="gap">
            <div class="fgroup">
              <label>Planned Move In Date</label>
              <fieldset class="cvr-bg-af">
                <input
                  type="date"
                  required
                  v-model="moveInDate"
                  class="datepicker-here"
                  :min="minDate"
                />
              </fieldset>
            </div>
            <div class="fgroup">
              <label>Move Out Date</label>
              <fieldset class="cvr-bg-af date">
                <input
                  type="date"
                  required
                  v-model="moveOutDate"
                  class="datepicker-here"
                  :min="minDate"
                />
              </fieldset>
            </div>
          </div>
          <div class="fgroup">
            <button type="submit" class="button">Next</button>
          </div>
        </form>
      </div>
      <!-- end of popup form -->
      <div id="popup-offer-rent" class="popup-form">
        <h2>{{ $t("findProperty.DetailProperty.MakeAnOffer") }}</h2>
        <form @submit.prevent="submitOffer">
          <div class="gap">
            <div class="fgroup">
              <label>Planned Move In Date</label>
              <fieldset class="cvr-bg-af">
                <input
                  type="date"
                  required
                  v-model="moveDate"
                  class="datepicker-here"
                  :min="minDate"
                />
              </fieldset>
            </div>
            <div class="fgroup">&nbsp;</div>
          </div>
          <div class="fgroup">
            <p class="amount clearfix">
              Effective Rent Per Month
              <strong>
                {{ rateEffectivePrice | convertToRupiah }}
              </strong>
            </p>
          </div>
          <div class="fgroup">
            <label>{{ $t("findProperty.DetailProperty.MakeAnOffer") }}</label>
            <input
              type="text"
              required
              placeholder="Rp 0"
              v-model="offer"
              @keyup="formatRupiah()"
            />
          </div>
          <div class="fgroup">
            <button type="submit" class="button">Send</button>
          </div>
          <div class="fgroup">
            <small
              >*Note that this is a binding offer. If offer is accepted, the
              transaction must be made.</small
            >
          </div>
        </form>
      </div>
      <!-- end of popup form -->
    </div>
    <!-- end of fgroup -->
    <div class="fgroup txt">
      <p>
        {{ $t("findProperty.DetailProperty.NotInclude") }}
        <a href="javascript:;" data-src="#popup-incl-txt-rent" data-fancybox>
          {{ $t("findProperty.DetailProperty.ShowDetail") }}
        </a>
      </p>
      <div id="popup-incl-txt-rent" class="popup-txt">
        <h2>{{ content.title }}</h2>
        <div class="format-text">
          <ul>
            <li v-for="(content, index) in content.contents" :key="index">
              - {{ content }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end of fgroup -->
    <button
      ref="logreg"
      style="visibility: hidden"
      data-src="#login-register"
      data-fancybox
    ></button>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TransactionRent",
  props: {
    property: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formulatedPrice: null,
      rateEffectivePrice: null,
      content: {},
      rentPeriod: null,
      rentPeriods: [],
      paymentOption: null,
      paymentOptions: [],
      moveDate: null,
      offer: null,
      moveInDate: null,
      moveOutDate: null,
      priceDetail: null
    };
  },
  mounted() {
    this.getContent("rent");
    this.getPaymentOptions();
    this.getRentPeriod();
  },
  watch: {
    rentPeriod() {
      this.getPaymentOptions();
      this.calculatePrice();
    },
    paymentOption() {
      this.calculatePrice();
    }
  },
  computed: {
    disabledAction() {
      if (this.rentPeriod !== null && this.paymentOption !== null) {
        return false;
      } else {
        return true;
      }
    },
    minDate() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    getContent(slug) {
      this.$axios
        .get(`/contents/${slug}`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.content = response.data.data.description;
        });
    },
    getRentPeriod() {
      this.$axios
        .get("/misc/rent-periods", {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.rentPeriods = response.data.data;
        });
    },
    getPaymentOptions() {
      this.$axios
        .get("/misc/payment-options", {
          params: {
            lang: this.$store.state.locale.lang,
            rentPeriod: this.rentPeriod
          }
        })
        .then(response => {
          this.paymentOptions = response.data.data;
        });
    },
    calculatePrice() {
      if (this.rentPeriod !== null && this.paymentOption !== null) {
        this.$axios
          .post("/properties/payment-option", {
            transactionType: 1,
            slug: this.paymentOption,
            ratesMonthly: this.property.ratesMonthly,
            rentPeriod: this.rentPeriod
          })
          .then(response => {
            this.formulatedPrice = response.data.data.ratePerMonth;
            this.rateEffectivePrice = response.data.data.rateEffectivePerMonth;
            this.$store.commit("SET_PRICE_DETAIL", {
              ...response.data.data,
              rentPeriod: this.rentPeriods.find(
                item => item.id === this.rentPeriod
              ).name,
              paymentOption: this.paymentOptions.find(
                item => item.slug === this.paymentOption
              ).name
            });
          });
      }
    },
    chatCSA() {
      if (this.$store.state.userdata) {
        this.$axios
          .post(`/conversations/init-csa`, {
            ref: `${this.base_url}/property/${this.property.slug}`,
            baseCity: this.property.city
          })
          .then(response => {
            this.$router.push(
              `/account/message?conversation=${response.data.data.id}`
            );
          })
          .catch(error => {
            console.log(error);
            this.$router.push("/account/message");
          });
      } else {
        this.$refs.logreg.click();
      }
    },
    offerProperty() {
      if (this.$store.state.userdata) {
        this.$refs.ShowOffer.click();
      } else {
        this.$refs.logreg.click();
      }
    },
    bookProperty() {
      if (this.$store.state.userdata) {
        this.$refs.ShowBook.click();
      } else {
        this.$refs.logreg.click();
      }
    },
    formatRupiah() {
      const angka = this.convertToAngka(this.offer);
      const rupiah = this.convertToRupiah(angka, false);
      this.offer = rupiah;
    },
    submitOffer() {
      this.$axios
        .post("/offers", {
          transactionType: 1,
          propertyId: this.property.id,
          moveDate: this.moveDate,
          ratesMonthly: this.convertToAngka(this.offer)
        })
        .then(response => {
          const close = document.getElementsByClassName("fancybox-close-small");
          close[0].click();
          this.$swal({
            icon: "success",
            text: response.data.message
          });
          this.$router.push("/account/transaction");
        });
    },
    submitBook() {
      this.$axios
        .post("/booking/rent", {
          transactionTypeId: 1,
          rentPeriodId: this.rentPeriod,
          paymentOption: this.paymentOption,
          propertyId: this.property.id,
          moveDate: this.moveInDate
        })
        .then(response => {
          const close = document.getElementsByClassName("fancybox-close-small");
          close[0].click();
          this.$store.commit("SET_BOOKING", response.data.data);
          // update move out
          const priceDetail = this.$store.state.priceDetail;
          this.$store.commit("SET_PRICE_DETAIL", {
            ...priceDetail,
            endDateRental: this.moveOutDate
          });
          this.$router.push(`/property/${this.$route.params.id}/booking/rent`);
        });
    }
  }
};
</script>
